<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card class="mt-2">
        <b-row class="mb-2">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col" class="bg-primary text-white align-middle">Questionnaire Detail - {{ updateQuestionnaire.title }}</th>
                <th scope="col" class="bg-primary text-white d-flex justify-content-end align-middle">
                  <b-button
                    v-b-tooltip.hover
                    :title="questions.length == 0 ? 'Please add at least one question to the table' : ''"
                    :disabled="questions.length == 0"
                    variant="info"
                    class="animate__animated animate__fadeIn mr-1"
                    style="background-color:white;color:#003b49"
                    size="sm"
                    @click="copySurvey(updateQuestionnaire.id, updateQuestionnaire.title)"
                  >
                    Duplicate
                  </b-button>

                  <b-button
                    v-b-tooltip.hover
                    :title="questions.length == 0 ? 'Please add at least one question to the table' : ''"
                    :disabled="questions.length == 0"
                    variant="success"
                    class="animate__animated animate__fadeIn"
                    style="background-color:white;color:#003b49"
                    size="sm"
                    @click="editSurvey()"
                  >
                    Update Questionnaire
                  </b-button>
                </th>
              </tr>
            </thead>
          </table>
        </b-row>

        <validation-observer ref="questionaireRules2">
          <b-row class="animate__animated ">
            <b-col cols="12" xl="12" md="12" sm="12">
              <b-row>
                <!-- Update Questionnaire -->
                <b-col cols="12" xl="4" md="4" lg="4" sm="12">
                  <b-form-group label="Questionnaire" label-for="questionnaire">
                    <validation-provider #default="{ errors }" name="questionnaire">
                      <b-input-group>
                        <b-form-input id="questionnaire" v-model.lazy="updateQuestionnaire.title" type="text" placeholder="Update Questionnaire" :state="questionnaireNameState" aria-describedby="input-live-feedback"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <b-form-invalid-feedback id="input-live-feedback">
                          Enter at least 5 letters
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- grade level -->
                <b-col cols="12" xl="4" md="4" lg="4" sm="12">
                  <b-form-group label="Grade Levels" label-for="gradeLeves">
                    <validation-provider #default="{ errors }" name="gradeLeves">
                      <v-select id="gradeLeves" v-model="selectedGradeLevels" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="title" :reduce="(val) => val" :options="gradeLevels" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- chart colour -->
                <b-col cols="12" sm="12" md="2" lg="2" xl="2">
                  <b-form-group label="Edit Chart Colour" label-for="charColour">
                    <ColourPicker id="chartColour" ref="chartColour" v-model="chartColour" :value="chartColour" picker="compact" />
                  </b-form-group>
                </b-col>
                <!-- question type -->
                <b-col cols="12" sm="12" md="2" lg="2" xl="2">
                  <b-form-group label="Question Type" label-for="questionType">
                    <validation-provider name="type" #default="{ errors }">
                      <v-select id="type" v-model="questionData.type" :options="questionTypes" :reduce="(val) => val" :filterable="false" label="name" :clearable="false" input-id="QuestionType" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Groups -->
                <b-col cols="12" xl="6" md="6" sm="12">
                  <b-form-group label="Groups" label-for="groups">
                    <validation-provider #default="{ errors }" name="group">
                      <v-select
                        id="group"
                        v-model="questionData.group"
                        :taggable="true"
                        :autoscroll="true"
                        maxHeight="50"
                        :create-option="(temp) => ({ id: 0, groupName: temp })"
                        :loading="groupSelectBoxLoading"
                        @option:created="createNewGroupValue"
                        @option:selected="selectedGroupValue"
                        :reduce="(val) => val"
                        :options="groupData"
                        label="groupName"
                        :clearable="true"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Question Type -->

                <!-- Add Question -->
                <b-col cols="12" xl="6" md="6" sm="12">
                  <b-form-group label="Question" label-for="title">
                    <validation-provider #default="{ errors }" name="title">
                      <b-input-group>
                        <b-form-input id="title" v-model.lazy="questionData.title" type="text" placeholder="Add Question" :state="titleState" aria-describedby="input-live-feedback"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <b-form-invalid-feedback id="input-live-feedback">
                          Enter at least 5 letters
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="12" md="12" sm="12" class="mb-1 d-flex justify-content-end  align-middle">
                  <b-button variant="success" class="pl-5 pr-5" :disabled="questionData.title === '' ? true : false" @click="addQuestion()">Add New Question +</b-button>
                </b-col>
              </b-row>

              <!-- <hr /> -->
              <!-- <b-row align-h="end">
                <b-col cols="6">
                  <b-form-group class="mb-40">
                    <b-input-group>
                      <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
                      <b-input-group-append>
                        <b-button @click="filter = ''" variant="primary">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row> -->
              <b-table
                :items="questions"
                :small="true"
                responsive
                :bordered="true"
                style="padding-bottom:30px"
                class="position-relative "
                head-variant="dark"
                :filter="filter"
                filter-debounce="250"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="column"
                hover
                :current-page="currentPage"
                :per-page="perPage"
                show-empty
                striped
                empty-text="No matching records found"
              >
                <template v-slot:cell(groups)="data">
                  <span class="font-weight-bold">
                    {{ data.item.datas[0].group.groupName }}
                  </span>
                </template>

                <template #cell(actions)="row">
                  <!-- <b-button  > {{ row.detailsShowing ? 'Hide' : 'Show' }} Questions </b-button> -->
                  <b-button size="sm" v-b-tooltip.hover :title="row.detailsShowing ? 'Hide Questions' : 'Show Questions'" variant="gradient-primary" class="btn-icon" @click="row.toggleDetails">
                    <feather-icon :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'" />
                  </b-button>
                  <!-- <b-button size="sm" variant="warning" class="mt-1"> Edit Group Name </b-button> -->
                  <b-button size="sm" v-b-tooltip.hover title="Edit Grup" variant="gradient-warning" class="btn-icon ml-1" @click="openGroupNameModal(row.item.datas[0].group)">
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </template>
                <!-- Column: Actions -->

                <template #row-details="row">
                  <b-card class="ml-5 mr-5">
                    <b-table :items="row.item.datas" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :fields="subColumn" sticky-header="500px">
                      <template v-slot:cell(questions)="subData">
                        <span>
                          {{ subData.item.title }}
                        </span>
                      </template>
                      <template v-slot:cell(status)="subData">
                        <span v-if="subData.item.type.status == 'A'">
                          <b-badge variant="light-primary">
                            <feather-icon icon="CheckCircleIcon" size="12" />
                            Questions
                          </b-badge>
                        </span>

                        <span v-else>
                          <b-badge variant="light-warning">
                            <feather-icon icon="MessageSquareIcon" size="12" />
                            Comment
                          </b-badge>
                        </span>
                      </template>
                      <template #cell(actions)="subData">
                        <b-button variant="outline-warning" size="sm" class="mr-1" @click="editQuestion(subData.item)">
                          <feather-icon icon="Edit3Icon" />
                          <span class="align-middle ml-50">Edit </span>
                        </b-button>

                        <b-button variant="outline-danger" size="sm" @click="deleteQuestion(subData.item.questionId)">
                          <feather-icon icon="DeleteIcon" />
                          <span class="align-middle ml-50">Delete </span>
                        </b-button>
                      </template>
                    </b-table>
                  </b-card>
                </template>
              </b-table>

              <div class="mx-2 mb-2">
                <b-row>
                  <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                  </b-col>

                  <!-- Pagination -->
                  <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
        <!-- edit group -->
        <b-modal id="modal-prevent-closing" ref="GroupModal" title="Edit Group" size="lg" v-model="groupModalShow" @hidden="resetGroupModal" @ok="updateGroupName">
          <validation-observer ref="editQuestionRules">
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-col cols="12" xl="12" md="12" sm="12">
                <b-form-group label="Group Name" label-for="editQuestion">
                  <validation-provider #default="{ errors }" name="editQuestion">
                    <b-input-group>
                      <b-form-textarea id="editQuestion" v-model.lazy="editGroup.groupName" type="text" rows="3" placeholder="Edit Group Name" :state="editGroupState" aria-describedby="input-live-feedback-edit" no-resize></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <b-form-invalid-feedback id="input-live-feedback-edit">
                        Enter at least 5 letters
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </form>
          </validation-observer>
        </b-modal>
        <!-- edit question -->
        <b-modal id="modal-prevent-closing" ref="modal" title="Edit Question" size="lg" v-model="modalShow" @hidden="resetModal" @ok="handleOk">
          <validation-observer ref="editQuestionRules">
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-col cols="12" sm="12" md="12" xl="12">
                <b-form-group label="Question Type" label-for="EditQuestionType">
                  <validation-provider name="EditQuestionType" #default="{ errors }">
                    <v-select id="EditQuestionType" v-model="editQuestionData.type" disabled :options="questionTypes" :reduce="(val) => val" :filterable="false" label="name" :clearable="false" input-id="EditQuestionType" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="12" md="12" sm="12">
                <b-form-group label="Group" label-for="editGroup">
                  <validation-provider #default="{ errors }" name="editGroup">
                    <v-select
                      id="editGroup"
                      disabled
                      v-model="editQuestionData.group"
                      :taggable="true"
                      :autoscroll="true"
                      maxHeight="50"
                      :create-option="(temp) => ({ id: 0, groupName: temp })"
                      :loading="groupSelectBoxLoading"
                      @option:created="editGroupValue"
                      @option:selected="selectedGroupValue"
                      :reduce="(val) => val"
                      :options="groupData"
                      label="groupName"
                      :clearable="true"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="12" md="12" sm="12">
                <b-form-group label="Question" label-for="editQuestion">
                  <validation-provider #default="{ errors }" name="editQuestion">
                    <b-input-group>
                      <b-form-textarea id="editQuestion" v-model.lazy="editQuestionData.title" type="text" rows="3" placeholder="Edit Question" :state="editTitleState" aria-describedby="input-live-feedback-edit" no-resize></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <b-form-invalid-feedback id="input-live-feedback-edit">
                        Enter at least 5 letters
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </form>
          </validation-observer>
        </b-modal>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BPagination, BFormRating, BIcon } from 'bootstrap-vue';
import settingsStoreModule from '../../settingsStoreModule';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';
import router from '@/router';
import axiosIns from '@/libs/axios';
import 'animate.css';
import ColourPicker from 'vue-colour-picker';
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BIcon,
    BFormRating,
    vSelect,
    //validation
    ValidationProvider,
    ValidationObserver,
    ColourPicker,
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  mounted() {
    this.getQuestionnaireData(this.$route.params.id);
    // this.getGroups();
    this.getGradeLevels();
  },
  data() {
    return {
      sortBy: 'type.status',
      sortDesc: false,
      dir: 'ltr',
      gradeLevels: [],
      selectedGradeLevels: [],
      updateQuestionnaire: {
        id: 0,
        title: null,
      },
      showSurveyDetail: false,
      groupSelectBoxLoading: false,
      questionData: {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: null,
      },
      editQuestionData: {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: null,
      },
      editGroup: {
        id: 0,
        groupName: '',
      },
      groupData: [],
      questionTypes: [
        { name: 'Comment', status: 'B' },
        { name: 'Answer', status: 'A' },
      ],
      loading: false,
      edit: null,
      newitem: '',
      preEditQuestionTitle: '',
      filter: null,
      filterOn: [],
      modalShow: false,
      groupModalShow: false,
      perPage: 10,
      questions: [],
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      chartColour: '',
      totalRows: 0,
      column: [
        {
          key: 'groups',
          sortable: false,
          label: 'Groups',
          thClass: 'text-left',
          tdClass: 'text-left',
          thStyle: 'width: 85%',
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: 'width: 15%',
        },
      ],
      subColumn: [
        {
          key: 'questions',
          sortable: false,
          label: 'Questions',
          thClass: 'text-left',
          tdClass: 'text-left',
          thStyle: 'width: 75%',
        },
        {
          key: 'status',
          sortable: true,
          label: 'Status',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: 'width: 5%',
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: 'width: 20%',
        },
      ],
    };
  },
  computed: {
    questionnaireNameState() {
      if (this.updateQuestionnaire.title === null) {
        return null;
      } else {
        return this.updateQuestionnaire.title.length > 5 ? true : false;
      }
    },
    titleState() {
      if (this.questionData.title === '') {
        return null;
      } else {
        return this.questionData.title.length > 5 ? true : false;
      }
    },
    editTitleState() {
      if (this.editQuestionData.title === '') {
        return null;
      } else {
        return this.editQuestionData.title.length > 5 ? true : false;
      }
    },
    editGroupState() {
      if (this.editGroup.groupName === '') {
        return null;
      } else {
        return this.editGroup.groupName.length > 5 ? true : false;
      }
    },
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    getQuestionnaireData(id) {
      this.loading = true;
      store
        .dispatch('settings/fetchQuestionnaireDetail', id)
        .then((res) => {
          this.updateQuestionnaire.id = res.data.id;
          this.updateQuestionnaire.title = res.data.title;
          this.selectedGradeLevels = res.data.gradeLevels;
          this.chartColour = res.data.chartColour;
          this.$refs.chartColour.colorValue = this.chartColour;
          this.showSurveyDetail = true;
          this.loading = false;
          this.preQuestionTitle = this.updateQuestionnaire.title;
          this.getGroups();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    onRowSelected(items) {
      console.log(items);
    },
    editSurvey() {
      if (this.updateQuestionnaire.title.length > 5) {
        this.loading = true;
        store
          .dispatch('settings/updateSurvey', {
            id: this.updateQuestionnaire.id,
            title: this.updateQuestionnaire.title.trim(),
            gradeLevels: this.selectedGradeLevels,
            changeTitle: this.preQuestionTitle == this.updateQuestionnaire.title.trim() ? 'no' : 'yes',
          })
          .then((res) => {
            if (res.status === 200 && res.data.existing == 0) {
              store
                .dispatch('settings/updateQuestionnaireColor', {
                  id: this.updateQuestionnaire.id,
                  chartColour: this.chartColour,
                })
                .then((res) => {
                  this.editToastMessage('Questionnaire');
                  this.loading = false;
                  router.push({ name: 'settings-surveys' });
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.loading = false;
              this.existingToastMessage('Questionnaire');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.questionnaireNameState;
      }
    },

    copySurvey(id, title) {
      const self = this;

      const currentDate = new Date();
      const randomTimeNumbers = currentDate.getDay() + '' + currentDate.getHours() + '' + currentDate.getMinutes() + '' + currentDate.getSeconds();

      this.$swal({
        title: 'Duplicate Survey',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, duplicate it!',
        input: 'text',
        inputPlaceholder: 'Enter survey name',
        inputValue: title + ' - copy_' + randomTimeNumbers,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to enter a survey name';
          }
        },

        preConfirm: (val) => {
          return axiosIns
            .post(`copyNameCheck`, { surveyTitle: val })
            .then((res) => {
              if (res.data.existing == 1) {
                throw new Error('This ' + title + ' name is existing');
              } else {
                return true;
              }
            })
            .catch((error) => {
              this.$swal.showValidationMessage(`${error.message}`);
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const surveyName = document.querySelector('.swal2-input').value;

          store
            .dispatch('settings/copyQuestionnaire', { surveyId: id, surveyTitle: surveyName })
            .then((res) => {
              let copiedId = res.data.id;
              self
                .$swal({
                  title: 'Copied!',
                  text: 'New questionnaire duplicate successfully created',
                  icon: 'success',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  showCancelButton: false,
                })
                .then((result) => {
                  router.push({
                    name: 'settings-surveys',
                  });
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    addQuestion() {
      this.$refs.questionaireRules2.validate().then((success) => {
        if (success) {
        }
      });
      const self = this;
      if (this.questionData.group != null) {
        if (this.questionData.title !== '' && this.questionData.title.length > 5) {
          const newQuestion = {
            title: this.questionData.title,
            groupId: this.questionData.group.id,
            status: this.questionData.type.status ? this.questionData.type.status : 'A',
          };
          store
            .dispatch('settings/controlQuestionResultTable', {
              surveyId: this.updateQuestionnaire.id,
              justResultTableControl: 1,
            })
            .then((res) => {
              if (res.data.existing == 1) {
                this.$swal({
                  title: 'Are you sure you want to add?',
                  text: 'The questionnaire to which we will add this question has been used before. If you say yes, this question will be added to the existing survey questions.',
                  icon: 'warning',
                  showCancelButton: true,
                  showDenyButton: true,
                  confirmButtonText: 'Yes, add it!',
                  cancelButtonText: 'Cancel',
                  denyButtonText: `Create as new questionnaire`,
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                    denyButton: 'btn btn-primary',
                  },
                  // reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    store
                      .dispatch('settings/addQuestionResultTable', {
                        surveyId: this.updateQuestionnaire.id,
                        addToQuestion: newQuestion,
                      })
                      .then((res) => {
                        self.getGroups();
                        this.clearQuestionData();
                        self.$swal('Added!', 'Your question added successful', 'success');
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else if (result.isDenied) {
                    store
                      .dispatch('settings/copyNewQuestionnaireForAdd', {
                        surveyId: this.updateQuestionnaire.id,
                        newQuestion: newQuestion,
                      })
                      .then((res) => {
                        let copiedId = res.data.id;
                        self
                          .$swal({
                            title: 'Copied!',
                            text: 'New questionnaire copy successfully created',
                            icon: 'success',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Switch to new questionnaire page',
                            customClass: {
                              confirmButton: 'btn btn-success',
                              cancelButton: 'btn btn-danger',
                            },
                          })
                          .then((result) => {
                            if (result.isConfirmed) {
                              router.push({
                                name: 'settings-surveyDetail',
                                params: { id: copiedId },
                                replace: true,
                              });
                              window.location.reload();
                            }
                          });
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                });
              } else {
                store
                  .dispatch('settings/addNewQuestionItem', newQuestion)
                  .then((res) => {
                    if (res.status === 201) {
                      this.getGroups();
                      this.clearQuestionData();
                      this.toastMessage('New Question');
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.selectGroupToastMessage();
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    createNewGroupValue(val) {
      this.groupSelectBoxLoading = true;
      if (this.groupData.find((x) => x.groupName.toUpperCase() == val.groupName.toUpperCase()) == undefined) {
        store
          .dispatch('settings/addNewGroupItem', {
            groupName: val.groupName.trim(),
            surveyId: this.updateQuestionnaire.id,
          })
          .then((response) => {
            if (response.status == 201) {
              //this.groupData.unshift(response.data);
              this.questionData.group = response.data;
              this.getGroups();
              this.toastMessage('New Group');
              this.groupSelectBoxLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.groupSelectBoxLoading = false;
      }
    },
    editGroupValue(val) {
      this.groupSelectBoxLoading = true;
      if (this.groupData.find((x) => x.groupName.toUpperCase() == val.groupName.toUpperCase()) == undefined) {
        store
          .dispatch('settings/addNewGroupItem', {
            groupName: val.groupName.trim(),
            surveyId: this.updateQuestionnaire.id,
          })
          .then((response) => {
            if (response.status == 201) {
              //this.groupData.unshift(response.data);
              this.questionData.group = {};
              this.getGroups();
              this.toastMessage('New Group');
              this.groupSelectBoxLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.groupSelectBoxLoading = false;
      }
    },
    editQuestion(question) {
      this.modalShow = true;
      this.preEditQuestionTitle = question.title;
      this.editQuestionData = {
        id: question.questionId,
        title: question.title,
        group: question.group,
        type: question.type.status === 'A' ? { name: 'Answer', status: 'A' } : { name: 'Comment', status: 'B' },
      };
    },
    getGradeLevels() {
      store
        .dispatch('settings/fetchGradeLevels', [])
        .then((res) => {
          this.gradeLevels = [];
          res.data.forEach((gl) => {
            this.gradeLevels.unshift({
              id: gl.id,
              title: gl.title,
              status: gl.status,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGroups() {
      this.groupSelectBoxLoading = true;
      store
        .dispatch('settings/fetchGroups', [])
        .then((res) => {
          this.questions = [];
          this.groupData = [];
          res.data.forEach((group) => {
            if (group.surveyId === this.updateQuestionnaire.id) {
              this.groupData.unshift({
                id: group.id,
                groupName: group.groupName,
                status: group.status,
              });
              if (group != null && group.questions.length > 0) {
                group.questions.forEach((data) => {
                  this.questions.unshift({
                    id: group.id,
                    questionId: data.id,
                    type: { status: data.status },
                    group: { id: group.id, groupName: group.groupName },
                    title: data.title,
                  });
                });
              }
              this.totalRows = this.groupData.length;
            }
          });
          this.questions = _(this.questions)
            .groupBy('group.id')
            .map((xxx) => ({
              datas: xxx,
            }))
            .value();
          this.groupSelectBoxLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectedGroupValue(val) {
      //   console.log(val);
    },
    deleteQuestion(id) {
      const self = this;
      if (id) {
        store
          .dispatch('settings/controlQuestionResultTable', {
            surveyId: this.updateQuestionnaire.id,
            questionId: id,
            justResultTableControl: 0,
          })
          .then((res) => {
            if (res.data.existing == 1) {
              this.$swal({
                title: 'Are you sure you want to delete?',
                text: 'There are answered survey questions related to this question. If you say yes, the survey questions related to this question will also be deleted.',
                icon: 'warning',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel',
                denyButtonText: `Create as new questionnaire`,
                customClass: {
                  confirmButton: 'btn btn-success',
                  cancelButton: 'btn btn-danger',
                  denyButton: 'btn btn-primary',
                },
                // reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  store
                    .dispatch('settings/deleteQuestionResultTable', {
                      surveyId: this.updateQuestionnaire.id,
                      questionId: id,
                    })
                    .then((res) => {
                      this.getGroups();
                      this.$swal('Deleted!', 'Your question has been deleted.', 'success');
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else if (result.isDenied) {
                  store
                    .dispatch('settings/copyNewQuestionnaireForDelete', {
                      surveyId: this.updateQuestionnaire.id,
                      questionId: id,
                    })
                    .then((res) => {
                      let copiedId = res.data.id;
                      self
                        .$swal({
                          title: 'Copied!',
                          text: 'New questionnaire copy successfully created',
                          icon: 'success',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Switch to new questionnaire page',
                          customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger',
                          },
                        })
                        .then((result) => {
                          if (result.isConfirmed) {
                            router.push({
                              name: 'settings-surveyDetail',
                              params: { id: copiedId },
                              replace: true,
                            });
                            window.location.reload();
                          }
                        });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              });
            } else {
              this.$swal({
                title: 'Are you sure you want to delete the question?',
                // text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
              }).then((result) => {
                if (result.isConfirmed) {
                  store
                    .dispatch('settings/deleteQuestion', id)
                    .then((res) => {
                      self.getGroups();
                      self.$swal.fire('Deleted!', 'Question has been deleted.', 'success');
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              });
            }
            //self.$swal.fire('Deleted!', 'Question has been deleted.', 'success');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      const self = this;
      this.$refs.editQuestionRules.validate().then((success) => {
        if (success) {
          if (this.editQuestionData.title !== '') {
            if (this.preEditQuestionTitle !== this.editQuestionData.title) {
              store
                .dispatch('settings/controlQuestionResultTable', {
                  surveyId: this.updateQuestionnaire.id,
                  questionId: this.editQuestionData.id,
                  justResultTableControl: 0,
                })
                .then((res) => {
                  if (res.data.existing == 1) {
                    this.$swal({
                      title: 'Are you sure you want to update?',
                      text: 'There are answered survey questions related to this question. If you say yes, the survey questions related to this question will also be updated.',
                      icon: 'warning',
                      showCancelButton: true,
                      showDenyButton: true,
                      confirmButtonText: 'Yes, update it!',
                      cancelButtonText: 'Cancel',
                      denyButtonText: `Create as new questionnaire`,
                      customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger',
                        denyButton: 'btn btn-primary',
                      },
                      // reverseButtons: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        const editQuestionData = {
                          id: this.editQuestionData.id,
                          title: this.editQuestionData.title,
                          groupId: this.editQuestionData.group.id,
                          status: this.editQuestionData.type.status,
                        };
                        store
                          .dispatch('settings/updateQuestionResultTable', {
                            surveyId: this.updateQuestionnaire.id,
                            question: editQuestionData,
                          })
                          .then((res) => {
                            self.modalShow = false;
                            self.preEditQuestionTitle = null;
                            self.resetModal();
                            self.getGroups();
                            this.$swal('Updated!', 'Your question has been updated.', 'success');
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else if (result.isDenied) {
                        const editQuestionData = {
                          id: this.editQuestionData.id,
                          title: this.editQuestionData.title,
                          groupId: this.editQuestionData.group.id,
                          status: this.editQuestionData.type.status,
                        };
                        store
                          .dispatch('settings/copyNewQuestionnaireForUpdate', {
                            surveyId: this.updateQuestionnaire.id,
                            question: editQuestionData,
                          })
                          .then((res) => {
                            self.preEditQuestionTitle = null;
                            self.resetModal();
                            self.modalShow = false;
                            let copiedId = res.data.id;
                            self
                              .$swal({
                                title: 'Copied!',
                                text: 'New questionnaire copy successfully created',
                                icon: 'success',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Switch to new questionnaire page',
                                customClass: {
                                  confirmButton: 'btn btn-success',
                                  cancelButton: 'btn btn-danger',
                                },
                              })
                              .then((result) => {
                                if (result.isConfirmed) {
                                  router.push({
                                    name: 'settings-surveyDetail',
                                    params: { id: copiedId },
                                    replace: true,
                                  });
                                  window.location.reload();
                                }
                              });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    });
                  } else {
                    this.handleSubmit();
                    this.preEditQuestionTitle = null;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.handleSubmit();
            }
          } else {
          }
        }
      });
    },
    updateGroupName(bvModalEvent) {
      bvModalEvent.preventDefault();
      const self = this;
      if (this.editGroup.groupName.length > 5) {
        store
          .dispatch('settings/controlGroupResultTable', {
            surveyId: this.updateQuestionnaire.id,
            groupId: this.editGroup.id,
          })
          .then((res) => {
            if (res.data.existing == 1) {
              this.$swal({
                title: 'Are you sure you want to update?',
                text: 'This group name is also used in existing surveys. If you say yes, this group name will be changed in other surveys.',
                icon: 'warning',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: 'Yes, update it!',
                cancelButtonText: 'Cancel',
                denyButtonText: `Create as new questionnaire`,
                customClass: {
                  confirmButton: 'btn btn-success',
                  cancelButton: 'btn btn-danger',
                  denyButton: 'btn btn-primary',
                },
                // reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  const editGroupData = {
                    id: this.editGroup.id,
                    title: this.editGroup.groupName.trim(),
                  };
                  store
                    .dispatch('settings/updateGroupResultTable', {
                      surveyId: this.updateQuestionnaire.id,
                      group: editGroupData,
                    })
                    .then((res) => {
                      self.modalShow = false;
                      self.resetGroupModal();
                      self.getGroups();
                      this.$swal('Updated!', 'Your group name has been updated.', 'success');
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else if (result.isDenied) {
                  const editGroupData = {
                    id: this.editGroup.id,
                    title: this.editGroup.groupName.trim(),
                  };
                  store
                    .dispatch('settings/copyNewQuestionnaireForGroupUpdate', {
                      surveyId: this.updateQuestionnaire.id,
                      group: editGroupData,
                    })
                    .then((res) => {
                      self.modalShow = false;
                      self.resetGroupModal();
                      self.getGroups();
                      let copiedId = res.data.id;
                      self
                        .$swal({
                          title: 'Copied!',
                          text: 'New group copy successfully created',
                          icon: 'success',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Switch to new questionnaire page',
                          customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger',
                          },
                        })
                        .then((result) => {
                          if (result.isConfirmed) {
                            router.push({
                              name: 'settings-surveyDetail',
                              params: { id: copiedId },
                              replace: true,
                            });
                            window.location.reload();
                          }
                        });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              });
            } else {
              store
                .dispatch('settings/editGroup', {
                  id: this.editGroup.id,
                  groupName: this.editGroup.groupName.trim(),
                })
                .then((res) => {
                  this.getGroups();
                  this.resetGroupModal();
                  this.modalShow = false;
                  this.editToastMessage('Group');
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.wrongToastMessage('Group');
      }
    },
    handleSubmit() {
      const editQuestionData = {
        id: this.editQuestionData.id,
        title: this.editQuestionData.title,
        groupId: this.editQuestionData.group.id,
        status: this.editQuestionData.type.status,
      };
      store
        .dispatch('settings/editQuestion', editQuestionData)
        .then((res) => {
          this.getGroups();
          this.resetModal();
          this.groupModalShow = false;
          this.editToastMessage('Question');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetModal() {
      this.editQuestionData = {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: {},
      };
    },
    resetGroupModal() {
      this.groupModalShow = false;
      this.editGroup = {
        id: 0,
        groupName: '',
      };
    },
    openGroupNameModal(group) {
      this.groupModalShow = true;
      //referans veri tipini primitive tipe çevirmek için Json yapısını kullanıyoruz.
      this.editGroup = JSON.parse(JSON.stringify(group));
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Added Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Warning',
          text: 'Please, chek your ' + val + ' input.',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      });
    },
    editToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Updated Successful',
          icon: 'CheckIcon',
          variant: 'success',
        },
      });
    },
    existingToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Warning',
          text: 'This ' + val + ' name is existing',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      });
    },
    selectGroupToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Warning',
          text: 'Please select group',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      });
    },
    clearQuestionData() {
      this.questionData = {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: null,
      };
    },
  },
};
</script>

<style lang="scss">
.swal2-actions {
  display: contents;
  justify-content: center;
}
.swal2-confirm {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.swal2-deny {
  margin-bottom: 0.5rem;
}
.color-input {
  width: 75% !important;
  margin-right: 2px !important;
  height: 37.78px;
  border: 1px solid #d8d6de;
}
.current-color {
  height: 37.78px;
}
.color-picker-container {
  width: 20% !important;
}
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
